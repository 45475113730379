@import url("https://fonts.googleapis.com/css2?family=Road+Rage&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.ItemWrapper {  
  position: relative;
  display: flex;
  height: fit-content;
  visibility: hidden;
  border-radius: 10px;
  margin-top: 30px;
  min-height: 100px;
  opacity: 0;
  
  backdrop-filter: blur(30px);
  background-color: hsla(0,0%,100%,.2);
  
  font-family: 'Roboto', sans-serif;
  animation: fade-in 1000ms ease forwards;

  padding: 10px;

}

.Vorschaubild {
  width: 250px;
  height: 140px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.ItemDatenWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-left: 20px;
  
}

.ItemDatenWrapper p {
  color: white;
  opacity: 0.9;
}

.ItemName {
  font-size: 25px;
  text-decoration: none;
  color: white;
  opacity: 0.9;
  transition: all 100ms;
  height: fit-content;

  backdrop-filter: brightness(0);
  border-radius: 5px;
  padding: 5px;

  background-color: transparent;
  border: none;
  width: fit-content;

  font-family: 'Roboto', sans-serif;

}

.hasLink:hover {
  text-decoration: underline;
}

.DeleteButton {
  cursor: pointer;
}

.DeleteButton:hover {
  transform: scale(1.05);
}

input,
button {
  height: 30px;
}

.SpecialWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  position: relative;
}

.SpecialDownWrapper {
  /* display: flex;
  justify-content: center;
  place-items: center; */
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ALERT {
  color: red;
  backdrop-filter: brightness(0);
  border-radius: 5px;
  padding: 5px;
  margin: 0;
  margin-top: 5px;
  font-size: 15px;
  font-weight: bold;
  align-self: flex-start;
}


.NeuerWunschHeaderWrapper {
  width: 100%;
}

.NeuerWunschHeader {
  font-family: 'Roboto', sans-serif;
  font-size: 25px;

  margin: 0;
  backdrop-filter: brightness(0);
  border-radius: 5px;
  padding: 10px;
}

.SubmitRequest {
  width: 100px;
  margin-top: 10px;
  background-color: inherit;
  color: white;
  border: 1px solid white;
  border-radius: 3px;
  position: relative;
}

.SubmitRequest span{
  position: relative;
  z-index: 2;
}

.SubmitRequest:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #00ffea;
  transition: all .35s;
}

.SubmitRequest:hover {
  color: #fff;
}

.SubmitRequest:hover:after{
  width: 100%;
}



.InputWrapper {
  display: flex;
  flex-direction: column;

  height: fit-content;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.InputWrapper p {


  font-size: 15px;
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

.NewItemInputText {
  border-radius: 4px;
  margin-top: 5px;

  font-family: inherit;
  font-size: 13px;

  background-color: inherit;
  border: 1px solid white;
  
  color: white;
}

.NewItemInputText::placeholder {
  color: white;
  opacity: 0.4;
  left: 10px;
  padding-left: 10px;
  
}

.NewItemInputText:focus-visible {
  background-color: inherit;
  border-color: none;
}

.noSHADOW {
  box-shadow: none;
  color: white;
}

.Starholder {
  margin-top: 10px;
}

.star {
  transition: all 200ms;
}

.star:hover {
  stroke: green;
  fill: green;
  transform: scale(1.3);
}

@media all and (max-width: 900px) {
  .SpecialDownWrapper {
    flex-direction: column;
    width: 100%;
  }
  .SubmitRequest {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Inputs {
    margin-top: 20px;
    grid-template-columns: 200px 200px;
    justify-content: space-around;
  }
  .InputWrapper {
    width: 100%;
    flex-direction: column;
  }
  .SpecialWrapper {
    margin-left: 0;
  }
  .NeuerWunschHeader {
    margin-left: 15px;
  }
}

@media all and (max-width: 630px) {
  .ItemWrapper {
    flex-direction: column;
  }
  .ItemDatenWrapper {
    margin: 0;
  }
  .ItemDatenWrapper p {
    width: 100%;
    text-align: center;
  }
  .ItemName {
    text-align: center;
    width: 100%;
    font-size: 20px;
    margin-top: 10px;
  }
  .VorschauBildWrapper {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .Vorschaubild {
    max-width: 100%;
    margin-top: 10px;
  }
  .Inputs {
    margin-top: 30px;
    grid-template-columns: 300px;
  }
  .InputWrapper {
    margin-top: 10px;
  }
  .SubmitRequest {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .NeuerWunschHeaderWrapper {
    margin-top: 10px;
  }
  .DeleteButton {
    position: absolute;
    top: 15px;
    right: 0px;
    height: 20px;
    width: 20px;
  }
  .Überschrift {
    font-size: 30px !important;
  }
  .WunschlistenHeader {
    font-size: 20px !important;
    width: 100%;
    text-align: center;
  }
  .SpecialWrapper {
    height: fit-content;
  }
}

@media all and (max-width: 400px) {
  .SpecialDownWrapper {
    width: 100%;
    justify-content: center;
    place-items: center;
  }
  .NewItemInputText {
    margin: 0;
  }
  .Inputs {
    width: 100% !important;
    position: relative;
  }
  .SpecialWrapper {
    margin-left: 0;
  }
  .NeuerWunschHeaderWrapper {
    width: 100%;
    text-align: center;
  }
}

@media all and (max-width: 300px) {
  .NeuerWunschHeader {
    margin: 0 !important;
  }
}
