@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Road+Rage&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&family=Roboto+Condensed:wght@700&display=swap);
.ConfirmWrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  place-items: center;
  flex-direction: column;
}

.ConfirmWrapper h1 {
  color: white;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-family: 'Oswald', sans-serif;
}

.ConfirmButtons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  place-items: center;
}

.ConfirmButtons button {
  width: 100px;
  height: 50px;
  border-radius: 5px;
  color: white;
  font-size: large;
  font-family: 'Oswald', sans-serif;
  opacity: 1;
}

.hidden {
  /* visibility: hidden; */
  pointer-events: none;
  opacity: 0.1;
}
  
.ConfirmButtons button{
  display: block;
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-family: sans-serif;
  text-decoration: none;
  color: #333;
  border: 2px solid #333;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all .35s;
}

.ConfirmButtons button span{
  position: relative;
  z-index: 2;
}

.ConfirmButtons button:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #ff003b;
  transition: all .35s;
}

.ConfirmButtons button:hover {
  color: #fff;
}

.ConfirmButtons button:hover:after{
  width: 100%;
}
.ItemWrapper {  
  position: relative;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  visibility: hidden;
  border-radius: 10px;
  margin-top: 30px;
  min-height: 100px;
  opacity: 0;
  
  -webkit-backdrop-filter: blur(30px);
  
          backdrop-filter: blur(30px);
  background-color: hsla(0,0%,100%,.2);
  
  font-family: 'Roboto', sans-serif;
  animation: fade-in 1000ms ease forwards;

  padding: 10px;

}

.Vorschaubild {
  width: 250px;
  height: 140px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.ItemDatenWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-left: 20px;
  
}

.ItemDatenWrapper p {
  color: white;
  opacity: 0.9;
}

.ItemName {
  font-size: 25px;
  text-decoration: none;
  color: white;
  opacity: 0.9;
  transition: all 100ms;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  -webkit-backdrop-filter: brightness(0);

          backdrop-filter: brightness(0);
  border-radius: 5px;
  padding: 5px;

  background-color: transparent;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  font-family: 'Roboto', sans-serif;

}

.hasLink:hover {
  text-decoration: underline;
}

.DeleteButton {
  cursor: pointer;
}

.DeleteButton:hover {
  transform: scale(1.05);
}

input,
button {
  height: 30px;
}

.SpecialWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  position: relative;
}

.SpecialDownWrapper {
  /* display: flex;
  justify-content: center;
  place-items: center; */
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ALERT {
  color: red;
  -webkit-backdrop-filter: brightness(0);
          backdrop-filter: brightness(0);
  border-radius: 5px;
  padding: 5px;
  margin: 0;
  margin-top: 5px;
  font-size: 15px;
  font-weight: bold;
  align-self: flex-start;
}


.NeuerWunschHeaderWrapper {
  width: 100%;
}

.NeuerWunschHeader {
  font-family: 'Roboto', sans-serif;
  font-size: 25px;

  margin: 0;
  -webkit-backdrop-filter: brightness(0);
          backdrop-filter: brightness(0);
  border-radius: 5px;
  padding: 10px;
}

.SubmitRequest {
  width: 100px;
  margin-top: 10px;
  background-color: inherit;
  color: white;
  border: 1px solid white;
  border-radius: 3px;
  position: relative;
}

.SubmitRequest span{
  position: relative;
  z-index: 2;
}

.SubmitRequest:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #00ffea;
  transition: all .35s;
}

.SubmitRequest:hover {
  color: #fff;
}

.SubmitRequest:hover:after{
  width: 100%;
}



.InputWrapper {
  display: flex;
  flex-direction: column;

  height: -webkit-fit-content;

  height: -moz-fit-content;

  height: fit-content;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.InputWrapper p {


  font-size: 15px;
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

.NewItemInputText {
  border-radius: 4px;
  margin-top: 5px;

  font-family: inherit;
  font-size: 13px;

  background-color: inherit;
  border: 1px solid white;
  
  color: white;
}

.NewItemInputText::placeholder {
  color: white;
  opacity: 0.4;
  left: 10px;
  padding-left: 10px;
  
}

.NewItemInputText:focus-visible {
  background-color: inherit;
  border-color: none;
}

.noSHADOW {
  box-shadow: none;
  color: white;
}

.Starholder {
  margin-top: 10px;
}

.star {
  transition: all 200ms;
}

.star:hover {
  stroke: green;
  fill: green;
  transform: scale(1.3);
}

@media all and (max-width: 900px) {
  .SpecialDownWrapper {
    flex-direction: column;
    width: 100%;
  }
  .SubmitRequest {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Inputs {
    margin-top: 20px;
    grid-template-columns: 200px 200px;
    justify-content: space-around;
  }
  .InputWrapper {
    width: 100%;
    flex-direction: column;
  }
  .SpecialWrapper {
    margin-left: 0;
  }
  .NeuerWunschHeader {
    margin-left: 15px;
  }
}

@media all and (max-width: 630px) {
  .ItemWrapper {
    flex-direction: column;
  }
  .ItemDatenWrapper {
    margin: 0;
  }
  .ItemDatenWrapper p {
    width: 100%;
    text-align: center;
  }
  .ItemName {
    text-align: center;
    width: 100%;
    font-size: 20px;
    margin-top: 10px;
  }
  .VorschauBildWrapper {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .Vorschaubild {
    max-width: 100%;
    margin-top: 10px;
  }
  .Inputs {
    margin-top: 30px;
    grid-template-columns: 300px;
  }
  .InputWrapper {
    margin-top: 10px;
  }
  .SubmitRequest {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .NeuerWunschHeaderWrapper {
    margin-top: 10px;
  }
  .DeleteButton {
    position: absolute;
    top: 15px;
    right: 0px;
    height: 20px;
    width: 20px;
  }
  .Überschrift {
    font-size: 30px !important;
  }
  .WunschlistenHeader {
    font-size: 20px !important;
    width: 100%;
    text-align: center;
  }
  .SpecialWrapper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media all and (max-width: 400px) {
  .SpecialDownWrapper {
    width: 100%;
    justify-content: center;
    place-items: center;
  }
  .NewItemInputText {
    margin: 0;
  }
  .Inputs {
    width: 100% !important;
    position: relative;
  }
  .SpecialWrapper {
    margin-left: 0;
  }
  .NeuerWunschHeaderWrapper {
    width: 100%;
    text-align: center;
  }
}

@media all and (max-width: 300px) {
  .NeuerWunschHeader {
    margin: 0 !important;
  }
}

.Downside {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  position: relative;
}

.Kasten {
  margin-top: 100px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);

  height: -webkit-fit-content;

  height: -moz-fit-content;

  height: fit-content;

  width: 90%;
  max-width: 1100px;
  padding: 20px;
  padding-top: 0px;

  display: flex;
  flex-direction: column;

  opacity: 0;
  visibility: hidden;
  transition: all 500ms;
  animation: fade-in 1000ms forwards ease;
}

.WunschlistenHeader {
  color: white;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;

}

@keyframes fade-in {
  to {
    opacity: 1;
    visibility: visible;
  }
}
body {
  background-image: url(/static/media/1762890.be6db2a5.jpg);
  background-size: cover;
}

#root {
  height: 100%;
}

.Wrapper {
  display: flex;
  place-items: center;
  flex-direction: column;
}

.Überschrift {
  color: white;
  font-family: "Mountains of Christmas", cursive;
  font-size: 50px;
  background-color: rgba(0, 0, 0, 0.2);
}

.selectPersonView {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;

  flex-wrap: wrap;
}

.PSBWrapper {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: flex-start;
  place-items: center;

  width: 180px;
  height: 40px;
  margin-top: 20px;
  background-color: white;

  border-radius: 5px;

  transition: all 200ms ease;
  cursor: pointer;
}

.PSBWrapper:hover {
  transform: scale(1.05);
}

.PSB {
  width: 100%;
  flex: 2 1;
  text-align: center;

  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Flex100 {
  width: 100%;
  flex: 1 1;
}

.ColoredSide {
  width: 100%;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  flex: 1 1;
}

#red {
  background-color: red;
}

#green {
  background-color: rgb(0, 255, 0);
}

#purple {
  background-color: purple;
}

#blue {
  background-color: aqua;
}

.PlsVerify {
  color: white;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

#login {
  display: flex;
  justify-content: center;
  place-items: center;
  height: 100%;
}

@media all and (max-width: 450px) {
  #login {
    flex-direction: column;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: red;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 0, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: orange;
}

