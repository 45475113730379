@import url("https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&family=Roboto+Condensed:wght@700&display=swap");


body {
  background-image: url("../images/1762890.jpg");
  background-size: cover;
}

#root {
  height: 100%;
}

.Wrapper {
  display: flex;
  place-items: center;
  flex-direction: column;
}

.Überschrift {
  color: white;
  font-family: "Mountains of Christmas", cursive;
  font-size: 50px;
  background-color: rgba(0, 0, 0, 0.2);
}

.selectPersonView {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;

  flex-wrap: wrap;
}

.PSBWrapper {
  display: flex;
  height: fit-content;
  justify-content: flex-start;
  place-items: center;

  width: 180px;
  height: 40px;
  margin-top: 20px;
  background-color: white;

  border-radius: 5px;

  transition: all 200ms ease;
  cursor: pointer;
}

.PSBWrapper:hover {
  transform: scale(1.05);
}

.PSB {
  width: 100%;
  flex: 2;
  text-align: center;

  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Flex100 {
  width: 100%;
  flex: 1;
}

.ColoredSide {
  width: 100%;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  flex: 1;
}

#red {
  background-color: red;
}

#green {
  background-color: rgb(0, 255, 0);
}

#purple {
  background-color: purple;
}

#blue {
  background-color: aqua;
}

.PlsVerify {
  color: white;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

#login {
  display: flex;
  justify-content: center;
  place-items: center;
  height: 100%;
}

@media all and (max-width: 450px) {
  #login {
    flex-direction: column;
  }
}
