@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

.Downside {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
  position: relative;
}

.Kasten {
  margin-top: 100px;
  border-radius: 10px;
  backdrop-filter: blur(10px);

  height: fit-content;

  width: 90%;
  max-width: 1100px;
  padding: 20px;
  padding-top: 0px;

  display: flex;
  flex-direction: column;

  opacity: 0;
  visibility: hidden;
  transition: all 500ms;
  animation: fade-in 1000ms forwards ease;
}

.WunschlistenHeader {
  color: white;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;

}

@keyframes fade-in {
  to {
    opacity: 1;
    visibility: visible;
  }
}